/* Base styles */
.home-container {
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.home-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f4f4f4;
    padding: 10px 20px;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.home-logo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: auto;
    cursor: pointer;
}

.home-logo h1 {
    margin: 0;
    font-size: 26px;
    font-weight: bold;
}

.home-logo h2 {
    margin: 0;
    font-size: 20px;
    font-weight: lighter;
    letter-spacing: 2px;
}

.nav-toggle {
    display: none; /* Hidden by default for desktop */
}

.home-nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
    padding-right: 50px; /* Add padding to move items slightly left */
}

.home-nav ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    justify-content: flex-end; /* Keep items towards the right */
    width: auto;
}

.home-nav ul li {
    margin: 0 20px;
    font-size: 18px;
    cursor: pointer;
    color: #000;
}

.home-main {
    display: flex;
    flex-direction: column; /* Stack text vertically */
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    background-color: #fff;
    margin-top: 80px; /* Ensure text is directly below the navbar */
    padding: 0 20px;
    text-align: center;
}

.welcome-text {
    font-size: 48px;
    font-weight: bold;
    animation: slideInFromBottom 1s ease-out forwards; /* Add the animation */
    margin-bottom: 10px;
    transform: translateY(10px); /* Ensure welcome text is slightly down */
    opacity: 0;
}

.subtext {
    font-size: 24px;
    font-weight: normal;
    opacity: 0;
    transform: translateY(20px);
}

.first-text {
    animation: slideInFromBottom 1s ease-out forwards;
    animation-delay: 1s; /* Delay this animation */
}

.second-text {
    animation: slideInFromBottom 1s ease-out forwards;
    animation-delay: 2s; /* Delay this animation further */
}

/* Keyframes for the slide-in animation */
@keyframes slideInFromBottom {
    0% {
        transform: translateY(130px); /* Start further down */
        opacity: 0; /* Start invisible */
    }
    100% {
        transform: translateY(30px); /* End position slightly lower */
        opacity: 1; /* End fully visible */
    }
}

/* Reviews Widget Animation */
.reviews-carousel-container {
    opacity: 0;
    transform: translateY(100px);
    animation: slideUp 1s ease-in-out forwards;
    animation-delay: 3.5s; /* Delay to appear after the second text */
    margin-top: 20px;
    width: 100%;
    max-width: 1200px;
    height: auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    background-color: #f9f9f9;
}

/* Individual review card styling */
.review-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
    transition: all 0.3s ease-in-out;
}

.review-card img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 10px;
}

.review-card h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #333;
}

.review-card p {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 10px;
}

.review-card .review-rating {
    font-size: 1.2rem;
    color: gold;
    margin-bottom: 10px;
}

.review-card .review-time {
    font-size: 0.8rem;
    color: #888;
}

/* Animation for sliding up */
@keyframes slideUp {
    from {
        transform: translateY(100px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .home-header {
        padding: 10px 15px;
    }

    .nav-toggle {
        display: block; /* Show toggle button in mobile view */
        cursor: pointer;
        font-size: 24px;
        color: #333;
        margin-right: 15px;
        padding: 5px 10px;
        z-index: 3000;
    }

    .home-nav {
        display: none; /* Hide desktop nav in mobile view */
    }

    .mobile-nav {
        position: fixed;
        top: 0;
        right: -250px; /* Start off-screen */
        height: 100%;
        width: 250px;
        background-color: #333;
        overflow-x: hidden;
        transition: right 0.3s ease;
        z-index: 1000;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-top: 60px;
    }

    .mobile-nav.active {
        right: 0;
        z-index: 3000; /* Slide in from the right */
    }

    .mobile-nav ul {
        list-style: none;
        padding: 0;
        width: 100%;
    }

    .mobile-nav ul li {
        padding: 16px 20px;
        text-align: left;
        font-size: 18px;
        width: 100%;
        color: #fff;
        cursor: pointer;
    }

    .mobile-nav ul li:hover {
        background-color: #575757;
    }

    .close-btn {
        position: absolute;
        top: 10px;
        right: 25px;
        font-size: 36px;
        cursor: pointer;
        color: #fff;
    }

    .home-main {
        align-items: center; /* Ensure content is centered */
        justify-content: flex-start;
        margin-top: 160px; /* Adjust gap between navbar and welcome text */
    }

    .home-main h1 {
        font-size: 32px;
        font-weight: bold;
    }

    .reviews-carousel-container {
        width: 95%;
        height: auto;
    }

    .review-card img {
        width: 60px;
        height: 60px;
    }

    .review-card h3 {
        font-size: 1rem;
    }

    .review-card p {
        font-size: 0.8rem;
    }
}

@media (max-width: 480px) {
    .reviews-carousel-container {
        width: 90%;
        padding: 10px;
    }
}

@media (min-width: 769px) {
    .home-header {
        padding: 10px 20px;
    }

    .home-nav {
        display: flex; /* Ensure nav is shown on desktop */
        align-items: center;
        justify-content: flex-end;
        padding-right: 30px;
        flex-grow: 1;
    }
}
ul, ol {
    list-style: none;
    margin: 0;
    padding: 0;
}
