.reviews-carousel-container {
  width: 90%;
  max-width: 1200px;
  margin: 2em auto;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden; /* Ensures only one review is visible */
}

.reviews-heading {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #333;
}

.reviews-heading .average-rating {
  font-size: 1rem;
  color: #777;
  margin-left: 10px;
}

.reviews-carousel {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden; /* Prevents overflow of reviews */
  width: 100%;
}

.review-cards-wrapper {
  display: flex;
  width: 100%;
  transition: transform 0.5s ease-in-out;
}

.review-card {
  flex: 0 0 100%; /* Makes sure only one review takes full width */
  max-width: 100%; /* Prevents exceeding container width */
  padding: 20px;
  text-align: center;
  box-sizing: border-box;
}

.review-card img {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
}

.review-card h3 {
  font-size: 1.2rem;
  margin: 10px 0;
  color: #333333;
}

.review-text {
  font-size: 0.9rem;
  color: #555;
  margin: 10px 0;
}

.review-time,
.review-rating {
  font-size: 0.8rem;
  color: #888;
}

button.prev-btn,
button.next-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 2rem;
  color: #aaa;
  cursor: pointer;
  z-index: 1;
}

button.prev-btn {
  left: 10px;
}

button.next-btn {
  right: 10px;
}

/* Responsive styles for mobile screens */
@media (max-width: 768px) {
  .reviews-carousel-container {
    width: 95%;
  }

  .review-card {
    flex: 0 0 100%; /* Ensures only one review is shown on mobile */
    max-width: 100%;
  }

  .reviews-heading {
    font-size: 1.2rem;
  }

  button.prev-btn,
  button.next-btn {
    font-size: 1.5rem; /* Adjust button size for mobile */
  }
}
