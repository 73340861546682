/* Main container styles for Components Section */
.home-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f4f4f4;
    padding: 10px 20px;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 2000; /* High z-index to ensure navbar stays on top */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.components-section {
    padding: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 30px; /* Gap between cards */
    max-width: 100%;
    overflow: hidden; /* Ensure no overflow */
}

/* Component card styles */
.component-card {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    width: calc(33.33% - 30px); /* Ensures 3 cards per row with gap */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    cursor: pointer;
    margin-bottom: 30px; /* Add margin at the bottom for space between rows */
}

.component-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.component-card img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    margin-bottom: 15px;
}

.component-card h3 {
    margin: 10px 0 5px;
    font-size: 18px;
    color: #333;
}

.component-card p {
    margin: 5px 0;
    font-size: 14px;
    color: #777;
}

/* List item styling for Key-Value pairs */
.component-details-list {
    list-style: none; /* Remove default bullets */
    padding-left: 0;
    margin: 0;
}

.component-details-list li {
    margin-bottom: 10px; /* Reduce gap between list items */
    display: flex; /* Align the key-value pair horizontally */
    align-items: center;
}

.component-details-list li::before {
    content: "•"; /* Custom bullet */
    color: #333; /* Bullet color */
    margin-right: 10px; /* Reduce the gap between bullet and key */
}

.component-details-list li strong {
    margin-right: 5px; /* Add a small gap between key and value */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 1024px) {
    .component-card {
        width: calc(50% - 30px); /* Two cards per row for medium-sized screens */
    }
}

@media (max-width: 768px) {
    .component-card {
        width: 100%; /* Single card per row for small screens */
    }
}



.carousel-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    max-width: 100%;
    margin: 20px auto 0;
    min-height: 400px; /* Fixed minimum height for the image container */
    overflow: hidden; /* Prevent any overflow */
}

.carousel-container img {
    width: 50%;
    /* height: 380px; */
    height: 50%;
    border-radius: 8px;
    object-fit: contain; /* Ensures the image fits within the specified height */
}

.carousel-container button {
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    position: absolute;
    top: 90%; /* Center the buttons vertically */
    transform: translateY(-50%);
    font-size: 18px; /* Font size for arrows */
    border-radius: 50%; /* Circular button design */
    z-index: 1000; /* Make sure the buttons appear above the image */
}

/* "Back" button */
.carousel-container button:first-of-type {
    left: 10px; /* Position of the Back button */
}

/* "Next" button */
.carousel-container button:last-of-type {
    right: 10px; /* Position of the Next button */
}

.carousel-container button:hover {
    background-color: rgba(0, 0, 0, 0.7); /* Darker background on hover */
}

/* GPU-specific details in component detail view */
.gpu-details {
    margin-top: 15px;
    text-align: left;
}

.gpu-details p {
    display: flex;
    align-items: center;
    margin: 10px 0;
    font-size: 16px;
    color: #555;
}

.gpu-details .icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    padding: 20px;
}

/* Book Now button styles */
.book-now-button {
    display: block;
    margin: 20px auto 30px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    text-align: center;
}

.book-now-button:hover {
    background-color: #0056b3;
}

/* Back button styles */
.back-button {
    position: fixed;
    top: 90px !important;
    left: 20px;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 3000;
}

.back-button img {
    width: 24px;
    height: 24px;
}

/* Responsive adjustments */
/* Adjust the carousel container to appear closer to the navbar */
@media (max-width: 500px) {
    .home-main {
        margin-top: 60px; /* Adjust the gap between navbar and main content */
    }

    .carousel-container {
        margin-top: 0; /* Remove margin to bring it closer to the navbar */
        padding: 0 10px;
        max-width: 100%; /* Add padding for some spacing */
    }

    .carousel-container img {
        height: 380px;
        width: 150%; /* Adjust image height for mobile view */
    }

    .carousel-container button {
        font-size: 16px;
    }

    .book-now-button {
        font-size: 14px;
        padding: 8px 16px;
    }
}

@media (max-width: 500px) {
    .home-main {
        margin-top: 50px; /* Reduce the gap even more for smaller screens */
    }

    .carousel-container {
        margin-top: 0;
        padding: 0 5px;
    }

    .carousel-container img {
        height: 220px; /* Reduce image height for very small screens */
    }

    .carousel-container button {
        font-size: 14px;
    }

    .back-button img {
        width: 20px;
        height: 20px;
    }
}

/* Game and laptop card styles */
.games-container, .refurbished-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: flex-start;
    padding: 20px;
    margin-top: 20px;
    overflow: hidden;
}

.game-card, .laptop-card {
    background-color: #f4f4f4;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.game-card {
    padding: 10px;
    width: 180px;
}

.laptop-card {
    padding: 15px;
    width: 220px;
}

.game-card:hover, .laptop-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.game-card img, .laptop-card img {
    width: 100%;
    object-fit: contain;
    border-radius: 5px;
}

.game-card img {
    height: 100px;
    margin-bottom: 10px;
}

.laptop-card img {
    height: 150px;
    margin-bottom: 15px;
}

.game-card h3, .laptop-card h3 {
    margin: 10px 0 5px;
    font-size: 16px;
    color: #333;
}

.game-card p, .laptop-card p {
    margin: 5px 0;
    font-size: 14px;
    color: #777;
}

/* Laptop details styles */
.laptop-details {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
    min-height: 150px;
}

.laptop-details p {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #333;
}

.laptop-details p img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    vertical-align: middle;
}

/* Book Now button styles */
.book-now-button {
    margin-top: 20px;
    margin-bottom: 30px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.book-now-button:hover {
    background-color: #0056b3;
}

/* Overlay styles for chat and game details */
.chat-now-overlay, .game-details-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
}

.chat-now-card, .game-details-card {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 500px;
    position: relative;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.chat-now-card h2, .game-details-card h2 {
    margin-bottom: 15px;
    font-size: 18px;
    color: #333;
}

.chat-now-card p, .game-details-card p {
    margin-bottom: 20px;
    font-size: 14px;
    color: #555;
}

/* Chat Now button styles */
.chat-now-button {
    background-color: #25d366;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
}

.chat-now-button:hover {
    background-color: #20b15a;
}

/* Close button styles for overlay */
.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
}

.close-button img {
    width: 20px;
    height: 20px;
}

/* Mobile view adjustments */
@media (max-width: 500px) {
    .refurbished-container {
        justify-content: center;
    }

    .game-details-card, .chat-now-card {
        width: 90%;
        padding: 15px;
        justify-content: center;
    }

    .close-button img {
        width: 18px;
        height: 18px;
    }

    .game-details p {
        font-size: 14px;
    }

    .game-details ul li {
        font-size: 14px;
    }

    .back-button {
        top: 70px;
        left: 10px;
    }
}

@media (max-width: 768px) {
    .component-card {
        width: 50%;
        align-items: center;
    }

    .carousel-container img {
        height: 280px;
    }

    .carousel-container button {
        font-size: 16px;
    }

    .book-now-button {
        font-size: 14px;
        padding: 8px 16px;
    }
}

@media (max-width: 500px) {
    .components-section,
    .games-container {
        justify-content: center;
    }

    .component-card,
    .game-card {
        width: 80%;
        max-width: 320px;
    }

    .carousel-container img {
        height: 220px;
    }

    .carousel-container button {
        font-size: 14px;
    }

    .back-button img {
        width: 20px;
        height: 20px;
    }

    .game-details-card, .chat-now-card {
        width: 90%;
        padding: 15px;
        justify-content: center;
    }

    .close-button img {
        width: 18px;
        height: 18px;
    }

    .game-details p {
        font-size: 14px;
    }

    .game-details ul li {
        font-size: 14px;
    }

    .back-button {
        top: 70px;
        left: 10px;
    }
}

/* Keyframes for slide up animation */
@keyframes slideUp {
    0% {
      opacity: 0;
      transform: translateY(100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
}

/* Styling for "Computer Sales and Services" */
.about-text-main {
    opacity: 0;
    animation: slideUp 1s ease-in-out forwards;
    animation-delay: 0.5s;
    font-size: 23px;
    font-weight: bold;
}

/* Styling for "Since 2014" */
.about-text-sub {
    opacity: 0;
    animation: slideUp 1s ease-in-out forwards;
    animation-delay: 1s;
    font-size: 23px;
    font-weight: bold;
}

/* Circular owner image with slide-up effect */
.owner-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    opacity: 0;
    animation: slideUp 1s ease-in-out forwards;
    animation-delay: 1.5s;
    margin-top: 20px;
}

/* Owner's name with slide-up effect */
.owner-name {
    font-size: 20px;
    font-weight: bold;
    opacity: 0;
    animation: slideUp 1s ease-in-out forwards;
    animation-delay: 2s;
    margin-top: 10px;
}

/* Sliding effect for contact icons (Phone, Gmail, WhatsApp) */
/* Sliding effect for contact icons (Phone, Gmail, WhatsApp) */
.contact-icons-row {
    display: flex;
    justify-content: center; /* Align icons in the center */
    gap: 60px; /* Add space between the icons */
    margin-top: 20px; /* Add margin at the top */
    opacity: 0; /* Set initial opacity to 0 for animation */
    animation: slideUp 1s ease-in-out forwards; /* Apply the slideUp animation */
    animation-delay: 2.5s; /* Adjust delay to follow the previous element */
}

.contact-icon, .social-icon {
    width: 50px; /* Set a fixed width for all icons */
    height: 50px; /* Set a fixed height for all icons */
    object-fit: contain; /* Keep aspect ratio of the image */
}

.contact-icon img, .social-icon img {
    width: 100%; /* Ensure the image fills the container */
    height: auto; /* Keep the height proportionate to width */
}

/* Sliding effect for social media icons (Instagram, Facebook, etc.) */
.social-media-icons {
    display: flex;
    justify-content: center; /* Align icons in the center */
    gap: 55px; /* Add space between the social icons */
    margin-top: 20px; /* Add margin at the top */
    opacity: 0; /* Set initial opacity to 0 for animation */
    animation: slideUp 1s ease-in-out forwards; /* Apply the slideUp animation */
    animation-delay: 3s; /* Adjust delay to appear after contact icons */
}

.social-media-icons a {
    display: inline-block;
}


.maps-location {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    opacity: 0;
    animation: slideUp 1s ease-in-out forwards;
    animation-delay: 3.5s;
    border-radius: 15px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

/* Sliding effect for the copyright text */
.copyright-text {
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
    color: #666;
    opacity: 0;
    animation: slideUp 1s ease-in-out forwards;
    animation-delay: 4s;
}

/* Style for the component details card */
.component-details-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    margin: 0 auto;
  }
  
  /* Style for the component image */
  .component-image {
    width: 100%; /* Adjust image width to fit the container */
    max-width: 400px; /* Optional max width to prevent overly large images */
    height: auto;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  /* Style for the component name */
  .component-details-card h3 {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
    text-align: center;
  }
  
  /* Style for the component price */
  .component-details-card p {
    font-size: 20px;
    color: #666;
    margin-bottom: 20px;
    text-align: center;
  }
  
  /* Style for the list of component details */
  .component-details-card ul {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 20px;
    text-align: left;
  }
  
  /* Style for the individual list items */
  .component-details-card ul li {
    font-size: 16px;
    color: #444;
    margin-bottom: 8px;
  }
  
  /* Style for the book-now button */
  .book-now-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease;
  }
  
  .book-now-button:hover {
    background-color: #0056b3;
  }
  
  /* Style for the back button */
  .back-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 10px;
    margin-bottom: 20px;
  }
  
  .back-button img {
    width: 25px;
    height: 25px;
  }
  
  .image-gallery-container {
    max-width: 800px; /* Set the desired width for the gallery */
    margin: 0 auto;
    margin-top: 30px; /* Add a gap between the image gallery and the previous content */
  }
  
  /* Custom styles for images inside the gallery */
  .image-gallery .image-gallery-image {
    width: 100%;
    height: 500px; /* Set a fixed height */
    object-fit: cover; /* Ensure the image fills the container while maintaining aspect ratio */
  }
  
  .image-gallery-thumbnail {
    width: 100px; /* Set a fixed width for thumbnails */
    height: 100px; /* Set a fixed height for thumbnails */
    object-fit: cover; /* Ensure the thumbnail fills the container */
  }
  /* Adds space between each bullet point */
/* Adds space between each bullet point */
.component-details-list li {
    margin-bottom: 55px; /* Adds gap between each bullet */
}

/* Makes the key part bold */
.component-details-list li strong {
    font-weight: bold; /* Makes the key bold */
    margin-right: 5px; /* Adds space between key and value */
}

/* Removes default bullets, adds custom ones */
.component-details-list li {
    list-style-type: disc; /* Uses a disc bullet */
    margin-left: 20px; /* Adds indentation for bullets */
}

/* Adds space between Price and the bullet points */
.component-price {
    margin-bottom: 50px; /* Adds space below Price */
}

/* General spacing for the content area */
.component-detail-section {
    margin-top: 20px; /* Adds space at the top of the detail section */
}
.component-details-list li {
    margin-bottom: 55px; /* Adds gap between each bullet */
    list-style-type: disc; /* Ensures bullet points */
    margin-left: 20px;
}

/* Makes the key part bold and adds a line break */
.component-details-list li strong {
    font-weight: bold; /* Makes the key bold */
    display: block; /* Forces the key to be on a new line */
}
